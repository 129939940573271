@use 'styles/variables' as var;
@use 'styles/functions' as fn;
@use 'styles/backgrounds' as bg;

.section {
  padding: fn.spacing(6) 0;
  background: var.$color-primary-highlight-blue2;
  max-width: 1000vh;
  overflow-x: hidden;
}

.rule1 {
  width: 345px;
  margin-top: fn.spacing(2);
}

.button-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.slider-tool-paper {
  box-shadow: none;
  position: relative;
  z-index: 2;
  background: var.$color-neutral-background-default;
  padding: fn.spacing(2);
  margin-bottom: fn.spacing(8);
  border: 1px solid var.$color-neutral-grey-5;
  border-radius: fn.spacing(1);
}

.disclaimer-container {
  padding: fn.spacing(0) fn.spacing(2);
  margin-bottom: fn.spacing(4);
}

.example-paper {
  text-align: center;
  box-shadow: none;
  background: bg.$bg-gradient-blue-2;
  color: white;
  border-radius: var.$card-mobile-border-radius;
  padding: fn.spacing(6) fn.spacing(2) fn.spacing(4);
  position: relative;
  z-index: 2;
  margin: 0 0 fn.spacing(4) 0;
}

.slider-tool-wrapper {
  width: 100%;
}

.slider-tool-title {
  text-align: left;
  margin-bottom: fn.spacing(2);
}

.slider-tool-min-mark {
  // value didn't fit within standard spacing
  transform: translateX(fn.spacing(3));
  color: var.$color-neutral-grey-3;
}

.slider-tool-max-mark {
  // value didn't fit within standard spacing
  transform: translateX(fn.spacing(-4));
  color: var.$color-neutral-grey-3;
}

.marks {
  display: none;
}

.slider-tool-stats-title {
  margin-bottom: fn.spacing(2);
}

.slider-tool-stats-title[data-disabled='true'] {
  color: var.$color-neutral-grey-3;
}

.slider-tool-value {
  color: var.$color-primary-achieve-blue;
  text-align: right;
}

.example-value {
  color: var.$color-neutral-background-default;
  padding-top: fn.spacing(1);
}

.example-title {
  padding-bottom: fn.spacing(3);
}

.example-divider {
  border-top: 1px solid var.$color-neutral-grey-3;
  padding-top: fn.spacing(2);
  margin-top: fn.spacing(2);
}

.icon-container {
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    padding: fn.spacing(3) fn.spacing(1.5);
  }
}

.paper-carousel-title-eyebrow {
  padding-bottom: fn.spacing(1);
}

.slider-divider {
  margin-bottom: fn.spacing(2);
  padding-bottom: fn.spacing(2);
}

@media screen and (min-width: var.$breakpoint-md) {
  .paper-carousel-title-eyebrow {
    padding-bottom: fn.spacing(2);
  }

  .button-container {
    display: block;
    align-items: normal;
    flex-direction: row;
  }

  .rule1 {
    transform: translate(fn.spacing(4));
    width: 95%;
  }

  .slider-container {
    margin-top: fn.spacing(8); // Ensure there is space to accommodate the Badge
    justify-content: center;
  }

  .example-paper {
    border-radius: var.$card-border-radius;
    margin: 0 0 fn.spacing(2);
    padding: fn.spacing(6) fn.spacing(4);
  }

  .slider-tool-paper {
    background: transparent;
    padding: 0;
    margin-bottom: 0;
    border: none;
    border-radius: 0;
  }
}

@media screen and (min-width: var.$breakpoint-lg) {
  .section {
    padding: fn.spacing(8) 0;
  }

  .rule1 {
    transform: translate(fn.spacing(8));
  }
}

@media screen and (min-width: var.$breakpoint-xl) {
  .rule1 {
    transform: translate(fn.spacing(8));
    width: 100%;
  }
}
